<template>
  <a @click="onItemClicked(item)"
    class="list-group-item flex-column align-items-start py-4 px-4"
    :class="{'apa-bg': item.user.id === 33}">
    <div class="d-flex w-100 justify-content-between">
      <div>
        <div class="d-flex w-100 align-items-center">
          <img v-if="item.image" :src="item.image" alt="Image placeholder" class="avatar avatar-xs mr-2">
          <h5 class="mb-1" :class="{'text-white': item.user.id === 33}">{{ item.user.name }}</h5>
        </div>
      </div>
      <small :class="{'text-white': item.user.id === 33}">{{ item.created | datetime }}</small>
    </div>
    <h4 class="mt-3 mb-1" :class="{'text-white': item.user.id === 33}">{{ item.subject }}</h4>
    <p class="text-xs mb-0" :class="{'text-white': item.user.id === 33}" v-html="item.comment"></p>
    <attachments v-if="attachments.length > 0" :documents="attachments"></attachments>
  </a>
  
</template>
<script>
import Attachments from '@/components/Feed/AttachmentTable.vue'

export default {
  components: {
    Attachments
  },
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      attachments: []
    };
  },
  methods: {
    onItemClicked(item) {
      this.$emit('item-clicked', item)
    }
  },
  mounted() {
    this.$store.dispatch("getDocs", {
      type: "comment_attachment",
      id: this.item.id,
    }).then((item) => {
      this.attachments = _.get(item, ["comment", this.item.id, "attach"], []);
    });
  }
};
</script>

<style>
.apa-bg {
  background: #9f2f37;
}
.apa-bg .text-right i,
.apa-bg .text-right a {
  color: white;
}
</style>